<template>
  <b-container class="mt-4">
    <b-card header-tag="header" header-class="header">
      <template #header>
        <label v-text="'Mesajlar'" class="mr-2 text-white"></label>
        <b-button variant="primary" to="/sendMessage">Mesaj Gönder</b-button>
      </template>
      <b-form-input v-model="messageFilter" placeholder="Mesaj içeriğini filtrele" class="mb-4"></b-form-input>

      <PMSTable
        :tableItems="getMessages"
        :tableFields="getMessageFields"
        :pageCount="getMessageTotalPageCount"
        :actionModes="['Sil']"
        @onPageChanged="onPageChanged"
        deleteMessage="Mesajı silmek istediğinizden emin misiniz?"
        @openPersonelDetail="openPersonelDetail"
        @multipleDeleteAction="deleteMultiple"
      ></PMSTable>
    </b-card>
  </b-container>
</template>

<script>
import PMSTable from "../../components/tables/GeneralTable.vue";
import { mapGetters } from "vuex";
export default {
  components: {
    PMSTable,
  },
  computed: {
    ...mapGetters([
      "getUser",
      "getMessages",
      "getMessageFields",
      "getMessageTotalPageCount",
      "getMessageFilter",
    ]),
    messageFilter: {
      get() {
        return this.getMessageFilter;
      },
      set(val) {
        this.$store.commit("updateMessageFilter", val);
        this.$store.dispatch("initMessages", { id: this.getUser.id });
      },
    },
  },
  created() {
    this.$store.dispatch("initMessages", {
      page: this.$router.history.current.query.page,
      id: this.getUser.id,
    });
    this.$store.commit("updatePMSTablePaginationCurrentPage", 1);
  },

  methods: {
    onPageChanged(value) {
      this.$store.dispatch("initMessages", {
        page: value,
        id: this.getUser.id,
      });
    },
    deleteMultiple(indexes) {
      this.$store.dispatch("deleteMessages", {
        ids: indexes,
        id: this.getUser.id,
      });
      this.$store.commit("updatePMSTablePaginationCurrentPage", 1);
    },
    openPersonelDetail(index, isReceiver) {
      this.$router.push({
        name: "PersonelDetail",
        params: {
          id: isReceiver
            ? this.getMessages[index].from
            : this.getMessages[index].to,
        },
      });
      this.$store.commit("updatePMSTablePaginationCurrentPage", 1);
    },
  },
};
</script>

<style scoped>
.header {
  background: rgb(108, 117, 125);
  background: linear-gradient(
    180deg,
    rgb(108, 117, 125) 10%,
    rgba(53, 57, 61, 1) 85%
  );
}
</style>